import * as React from "react";
import tw, { styled } from "twin.macro";

import { withHpIntro } from "./withHpIntro";

import * as Utils from "~/utils";
import * as Components from "~/components";

const StyledWrapperAll = styled.div(() => [
  tw`w-full bg-white`,
]);

const StyledWrapperSectionHeader = styled.div(() => [
  tw`w-full bg-white pt-4 px-4 md:px-0`,
]);

const StyledWrapperSectionPink = styled.div(() => [
  tw`w-full bg-pink-medium py-8 px-4 md:px-0`,
]);

const StyledWrapperSectionCta = styled.div(() => [
  tw`w-full bg-white py-8 px-8 md:px-0`,
]);

export const HpIntro = withHpIntro(({ usps }: Props) => (
  <StyledWrapperAll>
    <StyledWrapperSectionHeader>
      <Components.Container
        maxWidth="md"
      >
        <Components.HeaderVariation
          variation="navigationHpLogin"
        />
      </Components.Container>
    </StyledWrapperSectionHeader>
    <StyledWrapperSectionPink>
      <Components.Container
        maxWidth="md"
        spacing="og4"
      >
        <Components.Grid
          cols="1"
          colsMd="2"
          gap="4"
          alignItems="center"
        >
          <Components.Box
            fullWidth
          >
            <Components.TextVariation
              variation="headingdisplay"
              color="blueNavy"
            >
              {"Health Professionals"}
            </Components.TextVariation>
            <Components.TextVariation
              variation="heading1"
              color="greyDark"
            >
              {"Sign your patients up to My Journey and empower them during their breast cancer journey."}
            </Components.TextVariation>
          </Components.Box>
          <Components.Box
            fullWidth
          >
            <Components.Video
              title="About My Journey"
              videoUrl="https://www.youtube.com/watch?v=bPXfHOoo8Vo"
            />
          </Components.Box>
        </Components.Grid>
      </Components.Container>
    </StyledWrapperSectionPink>
    <Components.AppDownloadSection
      registerButton={(
        <Components.ButtonVariation
          variation="primary"
          href={Utils.HP_REGISTER_EXTERNAL}
          // @ts-expect-error incorrect types for anchor tag
          target="_blank"
          rel="noreferrer"
          external={true}
          key="register"
          fullWidth
        >
          Register
        </Components.ButtonVariation>
      )}
    />
    <Components.Section
      background="white"
      margin="none"
    >
      <Components.Container
        maxWidth="lg"
        spacing="lg"
        padding="md"
      >
        <Components.Row
          spacing="md"
        >
          <Components.Column>
            <Components.TextVariation
              variation="heading1"
              align="center"
            >
              {"Benefits for Health Professionals"}
            </Components.TextVariation>
            <Components.P
              align="center"
            >
              {"My Journey allows health professionals to sign up patients using their email or mobile number. It is the go-to resource for people affected by breast cancer, providing tailored information and support at every stage of the treatment journey and beyond."}
            </Components.P>
          </Components.Column>
        </Components.Row>
        <Components.Row
          spacing="md"
        >
          {usps.map(({ content, Icon }) => (
            <Components.Column
              key={content}
              md="1/3"
            >
              <Components.Column
                gutter="lg"
                spacing="lg"
                xs="auto"
                justify="center"
              >
                <Icon
                  viewBox="0 0 24 24"
                  style={{ width: "100%", height: "100%" }}
                />
              </Components.Column>
              <Components.P
                align="center"
                weight="bold"
              >
                {content}
              </Components.P>
            </Components.Column>
          ))}
        </Components.Row>
      </Components.Container>
    </Components.Section>
    <StyledWrapperSectionPink>
      <Components.PublicFooter />
    </StyledWrapperSectionPink>
    <StyledWrapperSectionCta>
      <Components.Container
        maxWidth="sm"
      >
        <Components.Grid
          cols="2"
          gap="4"
        >
          <Components.ButtonVariation
            variation="primary"
            href={Utils.HP_REGISTER_EXTERNAL}
            key="register"
            external={true}
            fullWidth
          >
            {"Register"}
          </Components.ButtonVariation>
          <Components.ButtonVariation
            variation="secondary"
            href={Utils.HP_LOGIN}
            key="login"
            fullWidth
          >
            {"Login"}
          </Components.ButtonVariation>
        </Components.Grid>
      </Components.Container>
    </StyledWrapperSectionCta>
    <Components.Meta
      title="Health Practioner | Introduction"
      metaDescription="Empower your patients with tailored breast cancer information reviewed by Australian health professionals. My Journey provides articles, resources and symptom tracking all in one application."
    />
  </StyledWrapperAll>
));

type Props = {
  usps: Array<USP>
}

type USP = {
  content: string
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
}
