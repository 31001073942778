import * as React from "react";
import * as Store from "store";
import * as Sentry from "@sentry/react";
import { deviceDetect, getUA } from "react-device-detect";
import { EndpointPatientSignIn } from "@myjourney/shared";
import {
  Diagnosis,
  DiagnosisTree,
  MergedArticle,
  Topic,
  UserMetadata,
  UserType,
} from "myjourney-frontend/src/vendor/umbraco";
import { HIDDEN_USER_TYPES } from "../utils/config";

import Cookies from "js-cookie";
import * as Hooks from "~/hooks";

import ImageProfileBg1 from "~/../assets/images/profile/profile-bg-1.jpg";
import ImageProfileBg2 from "~/../assets/images/profile/profile-bg-2.jpg";
import ImageProfileBg3 from "~/../assets/images/profile/profile-bg-3.jpg";
import ImageProfileBg4 from "~/../assets/images/profile/profile-bg-4.jpg";
import ImageProfileBg5 from "~/../assets/images/profile/profile-bg-5.jpg";
import ImageProfileBg6 from "~/../assets/images/profile/profile-bg-6.jpg";
import ImageProfileBg7 from "~/../assets/images/profile/profile-bg-7.jpg";
import ImageProfileBg8 from "~/../assets/images/profile/profile-bg-8.jpg";
import { Capacitor } from "@capacitor/core";

const profileBackgroundImages: Record<string, string> = {
  "default-1": ImageProfileBg1,
  "default-2": ImageProfileBg2,
  "default-3": ImageProfileBg3,
  "default-4": ImageProfileBg4,
  "default-5": ImageProfileBg5,
  "default-6": ImageProfileBg6,
  "default-7": ImageProfileBg7,
  "default-8": ImageProfileBg8,
};

export const PatientContext = React.createContext<PatientContextState>({
  auth: null,
  handleAuth: null,
  handleLogout: null,
  diagnosis: null,
  situation: null,
  treatment: null,
  locations: null,
  userTypes: null,
  setUserTypes: null,
  about: undefined,
  memberMetadata: null,
  userArticles: null,
  handleUserArticles: null,
  categories: null,
  handleCategories: null,
  handleNonEmptyCategories: null,
  nonEmptyCategories: null,
  setDiagnosisTree: null,
  diagnosisTree: null,
  situationIds: [],
  getSituationIds: null,
  handleMemberMetadata: null,
  saving: false,
  setSaving: null,
  profileBackgroundImages: profileBackgroundImages,
  profileImageUserContent: undefined,
  profileImageBackgroundContent: undefined,
});

export const PatientProvider: React.FC = ({ children }) => {
  const {
    getDiagnosisTree,
    getUserTypes,
    getMemberMetadata,
    getArticles,
    getCategories,
  } = Hooks.useContentApi();
  const symptomTrackerHook = Hooks.useSymptomTrackerApi();
  const diagnosisHook = Hooks.useDiagnosis();
  const deviceInfo = deviceDetect(getUA);
  const _auth = Store.get("auth");
  const [auth, setAuth] = React.useState<PatientContextState["auth"]>(_auth);
  const [diagnosisTree, setDiagnosisTree] =
    React.useState<DiagnosisTree | null>(null);
  const [userTypes, setUserTypes] = React.useState<Array<UserType> | null>(
    null
  );
  const [memberMetadata, setMemberMetadata] =
    React.useState<UserMetadata | null>(null);
  const [userArticles, setUserArticles] = React.useState<UserArticles | null>(
    null
  );
  const [categories, setCategories] = React.useState<Array<Topic> | null>(null);
  const [nonEmptyCategories, setNonEmptyCategories] =
    React.useState<Array<string> | null>(null);
  const [diagnosis, setDiagnosis] = React.useState<Diagnosis | null>(null);
  const [situation, setSituation] = React.useState<Diagnosis | null>(null);
  const [treatment, setTreatment] = React.useState<Diagnosis | null>(null);
  const [locations, setLocations] = React.useState<Array<Diagnosis> | null>(
    null
  );
  const [about, setAbout] = React.useState<Array<UserType> | undefined>();
  const [situationIds, setSituationIds] = React.useState<Array<string>>([]);
  const [saving, setSaving] = React.useState<boolean>(false);
  const { trackPatientAuthenticationInfo, trackPatientAuthenticationData } =
    Hooks.useAnalytics();

  React.useEffect(() => {
    if (deviceInfo.isBrowser) {
      Store.set(
        "auth",
        auth
          ? {
              patient_details: auth?.patient_details,
              patient_preferences: auth?.patient_preferences,
              salesforce_id: auth?.salesforce_id,
            }
          : null
      );
    }

    if (deviceInfo.isMobile) {
      Store.set("auth", auth);
    }

    // Check if v11 exists. If it does, logout user if offline is true.
    const protocolOrWhiteSpace = runtimeconfig.koben_umbraco_domain.includes(
      "https://"
    )
      ? ""
      : "https://";
    async function fetchOnlineStatus() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const bypassOffline = urlParams.get("bypassOffline");
      if (bypassOffline === "true" || Store.get("bypassOffline") === "true") {
        Store.set("bypassOffline", "true");
        return;
      } else {
        try {
          const res = await fetch(
            `${
              protocolOrWhiteSpace + runtimeconfig.koben_umbraco_domain
            }/umbraco/api/App/GetStatus/`
          );
          console.log(res.status);
          if (res.status === 404) {
            return;
          } else {
            const data = await res.json();
            if (data.offline === true) {
              Store.remove("bypassOffline");
              console.log(data.offline);
              console.log("data is offline, logging out");
              handleLogout();
            }
          }
        } catch (err) {
          console.log("fetch status is not available");
        }
      }
    }
    fetchOnlineStatus();

    console.log(`what platform am i??: ${Capacitor.getPlatform()}`);
    if (
      Capacitor.getPlatform() === "ios" ||
      Capacitor.getPlatform() === "android"
    ) {
      if (!auth?.koben_token || !auth?.application_token) {
        console.log("is ios. Logging out cause expired cookies");
        handleLogout();
      }
    } else {
      if (!Cookies.get("koben_token") || !Cookies.get("app_token")) {
        console.log("not ios. Logging out cause expired cookies");
        handleLogout();
      }
    }

    if (
      auth &&
      (Cookies.get("koben_token") || auth?.koben_token) &&
      (Cookies.get("app_token") || auth?.koben_token)
    ) {
      (async () => {
        const _diagnosisTree = await getDiagnosisTree();
        setDiagnosisTree(_diagnosisTree);
        const _userTypes = await getUserTypes();
        setUserTypes(_userTypes);
        const _memberMetadata = await getMemberMetadata(auth);
        setMemberMetadata(_memberMetadata);

        /**
         * The user or situation ids may have changed so we need to get the relevant categories or
         * articles.
         */
        const _userArticles = await getArticles();
        if (_userArticles) {
          setUserArticles(_userArticles);
        }
        const _categories = await getCategories();
        if (_categories) {
          setCategories(_categories);
        }

        /**
         * Filter the user types by the ids that the user has stored in their metadata.
         * Filter out user types that should not be displayed to the user such as age group.
         */
        const _about = _userTypes.filter(({ userTypeId }) => {
          return (
            !HIDDEN_USER_TYPES.includes(Number(userTypeId)) &&
            (auth.patient_details.usertype_ids || []).includes(userTypeId)
          );
        });
        setAbout(_about);
      })();
    }
  }, [auth?.key]);

  React.useEffect(() => {
    if (diagnosisTree && auth) {
      (async () => {
        const diagnosisDetails = await getDiagnosisDetails(
          auth.patient_details.diagnosis_ids
        );

        setSituationIds(diagnosisDetails.ids);
        setDiagnosis(diagnosisDetails.diagnosis);
        setSituation(diagnosisDetails.situation);
        setTreatment(diagnosisDetails.treatment);
        setLocations(diagnosisDetails.locations);
      })();
    }
  }, [diagnosisTree, auth]);

  const handleAuth = async (userDetails: EndpointPatientSignIn.UserData) => {
    Sentry.setUser({
      email: userDetails.patient_details.email,
    });

    trackPatientAuthenticationInfo({
      id: userDetails.salesforce_id,
      dob: `${new Date(userDetails.patient_details.dob).getFullYear()}`,
    });

    const diagnosisDetails = await getDiagnosisDetails(
      userDetails.patient_details.diagnosis_ids
    );

    const userTypeDetails = await getUserTypeDetails(
      userDetails.patient_details.usertype_ids
    );

    trackPatientAuthenticationData(
      {
        diagnosis: diagnosisDetails.diagnosis?.hpReferralTitle ?? "",
        situation: diagnosisDetails.situation?.hpReferralTitle ?? "",
        diagnosis_type: diagnosisDetails.treatment?.hpReferralTitle ?? "",
        diagnosis_location:
          diagnosisDetails.locations
            ?.map((location) => location.hpReferralTitle)
            .join(",") ?? "",
        type: userTypeDetails.map((userType) => userType.name).join(),
      },
      userDetails.patient_details.address_postcode
    );
    setAuth((prev) => ({
      ...userDetails,
      key: prev?.key ? prev.key + 1 : 1, // Add an incrementing key to make useEffect hook work when nested objects are changed
    }));
  };

  const handleLogout = async () => {
    setAuth(null);
    Cookies.remove("koben_token");
    Cookies.get("app_token");
  };
  const handleUserArticles = (articles: UserArticles) =>
    setUserArticles(articles);
  const handleCategories = (c: Array<Topic>) => setCategories(c);
  const handleNonEmptyCategories = (c: Array<string>) =>
    setNonEmptyCategories(c);
  const handleMemberMetadata = (metadata: UserMetadata | null) =>
    setMemberMetadata(metadata);

  const getDiagnosisDetails = async (
    diagnosisIds: Array<Array<string>>
  ): Promise<{
    ids: Array<string>;
    diagnosis: Diagnosis | null;
    situation: Diagnosis | null;
    treatment: Diagnosis | null;
    locations: Array<Diagnosis> | null;
  }> => {
    const _ids: Array<string> = [];

    let _situation!: Diagnosis | null;
    let _treatment!: Diagnosis | null;
    let _locations!: Array<Diagnosis> | null;

    const diagnosisTree = await getDiagnosisTree();

    const _diagnosis = diagnosisHook.getDiagnosisById(
      diagnosisIds[0]?.[0]?.toString(),
      diagnosisTree
    );
    if (_diagnosis) {
      if (_diagnosis.situationId) {
        _ids.push(_diagnosis.situationId);
      }
      _situation = diagnosisHook.getDiagnosisById(
        diagnosisIds[1]?.[0]?.toString(),
        _diagnosis.children
      );
      if (_situation) {
        if (_situation.situationId) {
          _ids.push(_situation.situationId);
        }
        _treatment = diagnosisHook.getDiagnosisById(
          diagnosisIds[2]?.[0]?.toString(),
          _situation.children
        );
        if (_treatment) {
          if (_treatment.situationId) {
            _ids.push(_treatment.situationId);
          }
          _locations = diagnosisIds[3]
            .map((id) =>
              diagnosisHook.getDiagnosisById(id, _treatment!.children)
            )
            .filter((d) => d) as Array<Diagnosis>;
          if (_locations) {
            _ids.concat(
              _locations
                .map((_location) => _location.situationId)
                .filter((s) => s) as Array<string>
            );
          }
        }
      }
    }

    return {
      ids: _ids,
      diagnosis: _diagnosis,
      situation: _situation,
      treatment: _treatment,
      locations: _locations,
    };
  };

  const getUserTypeDetails = async (
    userTypeIds: Array<string>
  ): Promise<Array<UserType>> => {
    const _userTypes = await getUserTypes();

    return _userTypes.filter((userType) =>
      userTypeIds.includes(userType.userTypeId)
    );
  };

  const getSituationIds = async (): Promise<Array<string>> => {
    if (situationIds.length) {
      return situationIds;
    }
    const diagnosisTree = await getDiagnosisTree();
    let _situationIds: Array<string> = [];
    if (auth) {
      const { diagnosis_ids } = auth.patient_details;
      const _diagnosis = diagnosisHook.getDiagnosisById(
        diagnosis_ids[0]?.[0]?.toString(),
        diagnosisTree
      );
      if (_diagnosis) {
        setDiagnosis(_diagnosis);
        if (_diagnosis.situationId) {
          _situationIds.push(_diagnosis.situationId);
        }
        const _situation = diagnosisHook.getDiagnosisById(
          diagnosis_ids[1]?.[0]?.toString(),
          _diagnosis.children
        );
        if (_situation) {
          setSituation(_situation);
          if (_situation.situationId) {
            _situationIds.push(_situation.situationId);
          }
          const _treatment = diagnosisHook.getDiagnosisById(
            diagnosis_ids[2]?.[0]?.toString(),
            _situation.children
          );
          if (_treatment) {
            setTreatment(_treatment);
            if (_treatment.situationId) {
              _situationIds.push(_treatment.situationId);
            }
            const _locations = diagnosis_ids[3]
              .map((id) =>
                diagnosisHook.getDiagnosisById(id, _treatment.children)
              )
              .filter((d) => !!d) as Array<Diagnosis>;
            if (_locations) {
              setLocations(_locations);
              const locationSituationIds = _locations
                .map(({ situationId }) => situationId)
                .filter((s) => !!s) as Array<string>;
              _situationIds = _situationIds.concat(locationSituationIds);
            }
          }
        }
      }
    }
    setSituationIds(_situationIds);
    return _situationIds;
  };

  const [profileImageUserId, setProfileImageUserId] = React.useState<string>();
  const [profileImageUserContent, setProfileImageUserContent] =
    React.useState<string>();
  const [profileImageBackgroundId, setProfileImageBackgroundId] =
    React.useState<string>();
  const [profileImageBackgroundContent, setProfileImageBackgroundContent] =
    React.useState<string>();

  React.useEffect(() => {
    if (typeof auth?.patient_preferences.profile_image_user !== "undefined") {
      setProfileImageUserId(auth.patient_preferences.profile_image_user);
    }
    if (
      typeof auth?.patient_preferences.profile_image_background !== "undefined"
    ) {
      setProfileImageBackgroundId(
        auth.patient_preferences.profile_image_background
      );
    }
  }, [
    auth?.patient_preferences.profile_image_user,
    auth?.patient_preferences.profile_image_background,
  ]);

  React.useEffect(() => {
    (async () => {
      if (auth && typeof profileImageUserId !== "undefined") {
        const imageContent = await symptomTrackerHook.getUserImage(
          profileImageUserId,
          auth?.koben_token.access_token
        );
        const imageBlob = await imageContent.blob();
        setProfileImageUserContent(URL.createObjectURL(imageBlob));
      }
    })();
  }, [profileImageUserId]);

  React.useEffect(() => {
    (async () => {
      if (auth && typeof profileImageBackgroundId !== "undefined") {
        if (profileImageBackgroundId.startsWith("default-")) {
          setProfileImageBackgroundContent(
            profileBackgroundImages[profileImageBackgroundId]
          );
        } else {
          const imageContent = await symptomTrackerHook.getUserImage(
            profileImageBackgroundId,
            auth?.koben_token.access_token
          );
          const imageBlob = await imageContent.blob();
          setProfileImageBackgroundContent(URL.createObjectURL(imageBlob));
        }
      }
    })();
  }, [profileImageBackgroundId]);

  return (
    <PatientContext.Provider
      value={{
        auth,
        handleAuth,
        handleLogout,
        handleUserArticles,
        handleCategories,
        diagnosis,
        situation,
        treatment,
        locations,
        userTypes,
        setUserTypes,
        about,
        memberMetadata,
        userArticles,
        categories,
        handleNonEmptyCategories,
        nonEmptyCategories,
        setDiagnosisTree,
        diagnosisTree,
        situationIds,
        getSituationIds,
        handleMemberMetadata,
        saving,
        setSaving,
        profileBackgroundImages,
        profileImageUserContent,
        profileImageBackgroundContent,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

export const PatientConsumer = PatientContext.Consumer;

export type PatientContextState = {
  auth: UserAuth | null;
  handleAuth: ((auth: UserAuth) => Promise<void>) | null;
  handleLogout: (() => void) | null;
  handleUserArticles: ((articles: UserArticles) => void) | null;
  handleCategories: ((categories: Array<Topic>) => void) | null;
  handleNonEmptyCategories: ((categories: Array<string>) => void) | null;
  handleMemberMetadata: ((metadata: UserMetadata | null) => void) | null;
  diagnosis: Diagnosis | null;
  situation: Diagnosis | null;
  treatment: Diagnosis | null;
  locations: Array<Diagnosis> | null;
  userTypes: Array<UserType> | null;
  setUserTypes: React.Dispatch<
    React.SetStateAction<Array<UserType> | null>
  > | null;
  userArticles: UserArticles | null;
  about: Array<UserType> | undefined;
  memberMetadata: UserMetadata | null;
  categories: Array<Topic> | null;
  nonEmptyCategories: Array<string> | null;
  diagnosisTree: DiagnosisTree | null;
  setDiagnosisTree: React.Dispatch<
    React.SetStateAction<DiagnosisTree | null>
  > | null;
  situationIds: Array<string>;
  getSituationIds: (() => Promise<Array<string>>) | null;
  setSaving: React.Dispatch<React.SetStateAction<boolean>> | null;
  saving: boolean;
  profileBackgroundImages: Record<string, string>;
  profileImageUserContent?: string;
  profileImageBackgroundContent?: string;
};

export type UserAuth = EndpointPatientSignIn.UserData & { key?: number };
export type UserArticles = Record<string, Array<MergedArticle>>;
