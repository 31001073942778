import * as React from "react";
import tw, { styled } from "twin.macro";

import { withHome } from "./withHome";

import { StyledHyperlink, StyledLink } from "~/components/Styled";
import {
  EmailLink,
  PhoneLink,
  PrivacyPolicyLink,
} from "~/components/Links/Links";

import * as Utils from "~/utils";
import * as Components from "~/components";

import AppStoreBadge from "~/../assets/icons/app-store-badge.svg";
import PlayStoreBadge from "~/../assets/icons/play-store-badge.svg";

import IconHomeStories from "~/../assets/icons/home-stories.svg";
import IconHomeSymptoms from "~/../assets/icons/home-symptoms.svg";
import IconHomeArticlesResources from "~/../assets/icons/home-articles-resources.svg";
import IconHomeNotes from "~/../assets/icons/home-notes.svg";

import ImagePortrait1 from "~/../assets/images/portrait/portrait-1.jpg";
import ImagePortrait2 from "~/../assets/images/portrait/portrait-2.jpg";
import ImagePortrait3 from "~/../assets/images/portrait/portrait-3.jpg";
import ImagePortrait4 from "~/../assets/images/portrait/portrait-4.jpg";
import ImagePortrait5 from "~/../assets/images/portrait/portrait-5.jpg";
import ImagePortrait6 from "~/../assets/images/portrait/portrait-6.jpg";
import ImagePortrait7 from "~/../assets/images/portrait/portrait-7.jpg";
import { Capacitor } from "@capacitor/core";

const StyledWrapperAll = styled.div(() => [tw`w-full bg-white`]);

const StyledWrapperSectionHeader = styled.div(() => [
  tw`w-full bg-white pt-4 px-4 md:px-0`,
]);

const StyledWrapperSectionWhite = styled.div(() => [
  tw`w-full bg-white py-8 px-4 md:px-0`,
]);

const StyledWrapperSectionPink = styled.div(() => [
  tw`w-full bg-pink-medium py-12 px-8 md:px-0`,
]);

const StyledWrapperSectionCta = styled.div(() => [
  tw`w-full bg-white py-8 px-8 md:px-0`,
]);

const StyledWrapperSectionImages = styled.div(() => [
  tw`w-full bg-white flex space-x-8 overflow-x-scroll px-4`,
]);

const StyledImage = styled.img(() => [tw`rounded-full p-2 w-150px md:w-220px`]);

const StyledWrapperSectionImagesB = styled.div(() => [
  tw`w-full flex max-w-screen-md mx-auto mb-og4 3xl:justify-center`,
]);

export const Home: React.FunctionComponent = withHome(() => {
  return (
    <StyledWrapperAll>
      <StyledWrapperSectionHeader>
        <Components.Container maxWidth="md">
          <Components.HeaderVariation variation="navigationLogin" />
        </Components.Container>
      </StyledWrapperSectionHeader>
      <StyledWrapperSectionWhite>
        <Components.Container maxWidth="md" spacing="og4">
          <Components.TextVariation variation="headingdisplay" color="blueNavy">
            {"Everyone's breast cancer experience is different"}
          </Components.TextVariation>
        </Components.Container>
      </StyledWrapperSectionWhite>
      <StyledWrapperSectionImages>
        <StyledWrapperSectionImagesB>
          {[
            ImagePortrait1,
            ImagePortrait2,
            ImagePortrait3,
            ImagePortrait4,
            ImagePortrait5,
            ImagePortrait6,
            ImagePortrait7,
          ].map((item) => (
            <StyledImage key={item} src={item} />
          ))}
        </StyledWrapperSectionImagesB>
      </StyledWrapperSectionImages>
      <StyledWrapperSectionWhite>
        <Components.Container maxWidth="md">
          <Components.Container maxWidth="md">
            <Components.Row spacing="ogxxs">
              <Components.Column>
                <Components.TextVariation
                  variation="paragraph1"
                  color="greyDark"
                >
                  {
                    "My Journey is a free online platform you can tailor to help with breast cancer or DCIS diagnosis any where, any time."
                  }
                </Components.TextVariation>
              </Components.Column>
            </Components.Row>
            <Components.Grid
              cols="1"
              colsMd="2"
              gapX="8"
              gapY="6"
              spacing="og8"
            >
              <Components.Box>
                <Components.Box spacing="og6">
                  <Components.TextVariation
                    variation="paragraph1bold"
                    color="blueNavy"
                  >
                    {
                      "Sign up here and use My Journey directly from your browser"
                    }
                  </Components.TextVariation>
                </Components.Box>
                <Components.Box>
                  <Components.ButtonVariation
                    variation="primary"
                    href={Utils.SIGNUP}
                    fullWidth
                  >
                    {"Sign up now"}
                  </Components.ButtonVariation>
                </Components.Box>
              </Components.Box>
              {!Capacitor.isNative ? (
                <Components.Box>
                  <Components.Box spacing="og6">
                    <Components.TextVariation
                      variation="paragraph1bold"
                      color="blueNavy"
                    >
                      {
                        "Download My Journey as a mobile app and sign up in the app"
                      }
                    </Components.TextVariation>
                  </Components.Box>
                  <Components.Row>
                    <Components.Column justify="center" xs="1/2">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://play.google.com/store/apps/details?id=com.bcna.myjourney&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                      >
                        <PlayStoreBadge height={50} />
                      </a>
                    </Components.Column>
                    <Components.Column justify="center" xs="1/2">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://apps.apple.com/au/app/my-journey-bcna/id1549503272?itsct=apps_box_badge&amp;itscg=30200"
                      >
                        <AppStoreBadge height={50} />
                      </a>
                    </Components.Column>
                  </Components.Row>
                </Components.Box>
              ) : null}
            </Components.Grid>
            <Components.Row spacing="ogsm">
              <Components.Column>
                <Components.TextContainer align="center">
                  <Components.TextLink
                    textVariation="textlink1"
                    href={Utils.HP_INTRO}
                  >
                    {"Information for Health Professionals"}
                  </Components.TextLink>
                </Components.TextContainer>
              </Components.Column>
            </Components.Row>
            <Components.HorizontalRule />
            <Components.Row>
              <Components.Column>
                <Components.InfoDropDown label="Do you think you may be at risk or are you supporting someone who has been diagnosed?">
                  <Components.TextContainer>
                    {
                      "No matter where you are with your breast cancer experience, you're not alone."
                    }
                  </Components.TextContainer>
                </Components.InfoDropDown>
              </Components.Column>
            </Components.Row>
          </Components.Container>
        </Components.Container>
      </StyledWrapperSectionWhite>
      <StyledWrapperSectionPink>
        <Components.Container maxWidth="md">
          <Components.Container maxWidth="md">
            <Components.Row spacing="ogxxs">
              <Components.Column>
                <Components.TextVariation variation="heading1" color="blueNavy">
                  {"What is My Journey"}
                </Components.TextVariation>
              </Components.Column>
            </Components.Row>
            <Components.Row spacing="ogsm">
              <Components.Column>
                <Components.TextVariation
                  variation="paragraph1"
                  color="greyDark"
                >
                  {
                    "No matter where you are with your breast cancer experience, you're not alone."
                  }
                </Components.TextVariation>
              </Components.Column>
            </Components.Row>
            <Components.Row spacing="ogsm" justify="center">
              <Components.Column md="3/4">
                <Components.Video
                  title="About My Journey"
                  duration="3:33"
                  videoUrl="https://www.youtube.com/watch?v=bPXfHOoo8Vo"
                />
              </Components.Column>
            </Components.Row>
            <Components.Row spacing="ogxxs">
              <Components.Column>
                <Components.TextVariation variation="heading1" color="blueNavy">
                  {"What's included?"}
                </Components.TextVariation>
              </Components.Column>
            </Components.Row>
            <Components.Row spacing="ogxxs">
              {[
                {
                  icon: <IconHomeStories />,
                  label: "Stories",
                  content:
                    "Hear from other people who have been right where you are.",
                },
                {
                  icon: <IconHomeSymptoms />,
                  label: "Symptom Tracker",
                  content: "Keep track of symptoms during and after treatment",
                },
                {
                  icon: <IconHomeArticlesResources />,
                  label: "Articles & Resources",
                  content:
                    "Read articles, listen to podcasts and view webinars",
                },
                {
                  icon: <IconHomeNotes />,
                  label: "Notes",
                  content:
                    "Jot down important questions for your doctors, or add journal entries",
                },
              ].map((item, index) => (
                <Components.Column key={index} md="1/2" spacing="ogxxs">
                  <Components.Row wrap="none" gutter="md">
                    <Components.Column gutter="md" grow>
                      {item.icon}
                    </Components.Column>
                    <Components.Column gutter="md">
                      <Components.TextVariation
                        variation="paragraph1bold"
                        color="blueNavy"
                      >
                        {item.label}
                      </Components.TextVariation>
                      <br />
                      <Components.TextVariation
                        variation="paragraph1"
                        color="greyDark"
                      >
                        {item.content}
                      </Components.TextVariation>
                    </Components.Column>
                  </Components.Row>
                </Components.Column>
              ))}
            </Components.Row>
            <Components.Row spacing="ogxxs">
              <Components.Column>
                <Components.Blockquote author="Jodie, Ballarat">
                  {
                    "The My Journey app is something I can trust and rely on to be accurate and up-to-date."
                  }
                </Components.Blockquote>
              </Components.Column>
            </Components.Row>
          </Components.Container>
        </Components.Container>
      </StyledWrapperSectionPink>
      <StyledWrapperSectionWhite css={tw`md:mt-8`}>
        <Components.Container maxWidth="md">
          <Components.Row>
            <Components.Column>
              <Components.TextVariation variation="heading1" color="blueNavy">
                {"Frequently asked questions"}
              </Components.TextVariation>
              <Components.GlossaryAccordion
                items={[
                  {
                    label: "How can I get support with My Journey",
                    content: (
                      <>
                        {
                          "If you need assistance with using My Journey or would like to provide some feedback please contact us on "
                        }
                        <PhoneLink />
                        {" or email us at "}
                        <EmailLink />
                        {"."}
                      </>
                    ),
                  },
                  {
                    label: "Why do I have to provide personal information?",
                    content: (
                      <>
                        {
                          "The amount of information available about breast cancer can be confusing and overwhelming. By providing information about your diagnosis and situation we are able to ensure the information we provide is specific to your needs."
                        }
                        <br />
                        <br />
                        {
                          "BCNA also uses information we gather to assist us in our work to improve services and to be able to report to funding bodies. The information is de-identified, so your personal information is  not shared. If you have any privacy concerns please read our "
                        }
                        <PrivacyPolicyLink />
                        {" or contact us on "}
                        <PhoneLink />
                        {" if you would like more information."}
                      </>
                    ),
                  },
                  {
                    label: "What if I want a printed book?",
                    content: (
                      <>
                        {
                          "We understand that for some people a printed book is more suited to their individual needs. You can order printed resources by calling the BCNA Helpline on "
                        }
                        <PhoneLink />
                        {"."}
                      </>
                    ),
                  },
                  {
                    label: "How does My Journey assit Health Professionals?",
                    content: (
                      <>
                        {
                          "My Journey allows health professionals to sign up patients using their email and empower them during their breast cancer journey. "
                        }
                        <StyledLink to={Utils.HP_INTRO}>
                          {"Learn more"}
                        </StyledLink>
                      </>
                    ),
                  },
                  {
                    label: "Who is Breast Cancer Network Australia?",
                    content: (
                      <>
                        {
                          "Breast Cancer Network Australia (BCNA) is Australia's leading breast cancer consumer organisation. For the past 22 years, we have worked tirelessly to ensure that all Australians who are affected by breast cancer receive the very best care, treatment and support. "
                        }
                        <StyledHyperlink
                          href="https://www.bcna.org.au/about-us/who-we-are/"
                          target="_blank"
                          rel="noopener"
                        >
                          {"Learn more"}
                        </StyledHyperlink>
                      </>
                    ),
                  },
                ]}
              />
            </Components.Column>
          </Components.Row>
        </Components.Container>
      </StyledWrapperSectionWhite>
      <StyledWrapperSectionPink>
        <Components.PublicFooter />
      </StyledWrapperSectionPink>
      <StyledWrapperSectionCta>
        <Components.Container maxWidth="sm">
          <Components.Grid cols="2" gap="4">
            <Components.ButtonVariation
              variation="primary"
              href={Utils.SIGNUP}
              key="signup"
              fullWidth
            >
              {"Sign up now"}
            </Components.ButtonVariation>
            <Components.ButtonVariation
              variation="secondary"
              href={Utils.USER_LOGIN}
              key="login"
              fullWidth
            >
              {"Login"}
            </Components.ButtonVariation>
          </Components.Grid>
        </Components.Container>
      </StyledWrapperSectionCta>
      <Components.Meta
        title="Home"
        metaDescription="The My Journey app provides reliable information tailored to your breast cancer experience and helps you make the best decisions about treatment and care."
      />
    </StyledWrapperAll>
  );
});
