import React from "react";
import { SIGNUP } from "~/utils/constants";
import { Column, Container, Row, Section } from "../Styled";
import AppStoreBadge from "~/../assets/icons/app-store-badge.svg";
import PlayStoreBadge from "~/../assets/icons/play-store-badge.svg";
import { StyledH4 } from "./styledAppDownloadSection";

import * as Components from "~/components";

export const AppDownloadSection: React.FC<Props> = ({ registerButton }) => (
  <Section
    background="white"
    padding="md"
  >
    <Container
      maxWidth="md"
      spacing="none"
      padding="md"
    >
      <Row>
        <Column>
          <StyledH4
            align="center"
          >
            {"Sign up online or download the app:"}
          </StyledH4>
        </Column>
      </Row>
      <Row
        justify="center"
        items="center"
        gutter="lg"
      >
        <Column
          shrink
          gutter="lg"
          spacing="sm"
        >
          {registerButton ? registerButton : (
            <Components.ButtonVariation
              variation="primary"
              href={SIGNUP}
            >
              {"Sign up"}
            </Components.ButtonVariation>
          )}
        </Column>
        <Column
          shrink
          gutter="lg"
          spacing="sm"
        >
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.bcna.myjourney&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <PlayStoreBadge
              height={50}
            />
          </a>
        </Column>
        <Column
          shrink
          gutter="lg"
          spacing="sm"
        >
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.apple.com/au/app/my-journey-bcna/id1549503272?itsct=apps_box_badge&amp;itscg=30200"
          >
            <AppStoreBadge
              height={50}
            />
          </a>
        </Column>
      </Row>
    </Container>
  </Section>
);

type Props = {
  registerButton?: JSX.Element
}

